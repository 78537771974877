<template>
  <div class="notification-wrapper" role="alert">
    <div
      v-if="notification"
      class="notification"
      :class="classes"
      :data-tosca="getToscaPrefix('notification-' + notification.type, toscaPrefix)"
    >
      <p>{{ notification.text }}</p>
      <button
        type="button"
        :title="$t('global.close')"
        :data-tosca="getToscaPrefix('notification-close-' + notification.type, toscaPrefix)"
        @click="onClick"
      >
        <spar-icon-sprite symbol="close" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SparIconSprite from "~/components/shared/SparIconSprite/SparIconSprite.vue";
import useNotification, {
  NotificationType,
} from "~/components/shared/SparNotification/useNotification";
import useI18n from "~/composables/i18n/useI18n";
import { getToscaPrefix } from "~/utils/ui";

const { notification } = useNotification();
const { $t } = useI18n(); // Explicit Import for Storybook

const onClick = () => {
  if (!notification.value) return;
  notification.value.dismiss();
};

const classes = computed(() => {
  if (!notification.value) return;
  switch (notification.value.type) {
    case NotificationType.Error:
      return "notification--error";
    case NotificationType.Success:
      return "notification--success";
    case NotificationType.Warning:
      return "notification--warning";
  }
});

const toscaPrefix = computed(() => {
  if (!notification.value) return;
  return notification.value.toscaPrefix;
});

const enableAutoHide = (timeout: number) => {
  setTimeout(() => {
    notification.value?.dismiss();
  }, timeout);
};

// If a new notification becomes visible, check if auto hide is enabled
watch(
  () => notification.value,
  (val) => {
    if (val?.autoHideTimeout) {
      enableAutoHide(val.autoHideTimeout * 1000);
    }
  },
);
</script>

<style lang="scss">
@use "./SparNotification.scss";
</style>
