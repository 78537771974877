import type { Footer, Header, HeaderReduced } from "@shop-storefront/utils";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import {
  footerReferences,
  headerReferences,
} from "~/utils/contentstack/constants/references.constants";
import { resolvePageFooter } from "~/utils/contentstack/resolvers/footer/footer.resolvers";

export const useLayout = () => {
  const { $contentstack } = useNuxtApp();

  const getPageHeader = async () => {
    const { data: content } = await useAsyncData(ContentType.header, async () => {
      return await $contentstack.getEntries<Header[]>({
        type: ContentType.header,
        includeCount: true,
        includeReference: headerReferences as string[],
      });
    });

    if (!content.value) return;
    return content.value[0][0];
  };

  const getPageHeaderReduced = async () => {
    const { data: content } = await useAsyncData(ContentType.headerReduced, async () => {
      return await $contentstack.getEntries<HeaderReduced[]>({
        type: ContentType.headerReduced,
        includeCount: true,
      });
    });

    if (!content.value) return;
    return content.value[0][0];
  };

  const getPageFooter = async () => {
    const { data: content } = await useAsyncData(ContentType.footer, async () => {
      return await $contentstack.getEntries<Footer[]>({
        type: ContentType.footer,
        includeCount: true,
        includeReference: footerReferences as string[],
      });
    });

    if (!content.value) return;
    return resolvePageFooter(content.value[0][0]);
  };

  return {
    getPageFooter,
    getPageHeader,
    getPageHeaderReduced,
  };
};
